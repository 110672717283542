
import Vue from 'vue';
import dayjs from 'dayjs';
import type { PropType } from 'vue';

import { IDateTimeSearch } from '@/types/misc_data';
import { createPlaceholderImage, dateToAustrianTimezone } from '@/_helpers/misc_helper';
import { Site, Truck, Model, ITruckGroupBySize } from '~/types/truck';

import RibbonBanner from '@/components/general/utils/RibbonBanner.vue';
import SlotGridNew from '@/components/general/calendar/SlotGridNew.vue';
import TruckInfoNew from '@/components/truck/TruckInfoNew.vue';

import CART, { SlotType } from '@/store/modules/CartModule';
import MAP from '@/store/modules/MapModule';
import TRUCKS from '@/store/modules/TruckModule';
import UTILS from '@/store/modules/UtilityModule';
import MISC_DATA from '@/store/modules/MiscDataModule';
import { ErrorResponse } from '~/types/api_helper';
import { ITruckClickPayLoad, ISlotCLickedOrConfirmedPayload } from '~/plugins/tracking';
import globalAws from '@/mixins/globalAws';
import SelectTruckSize from '@/components/map/SelectTruckSize.vue';
import currentDomain from '@/mixins/currentDomain';

interface StringIndex {
  [key: string]: any;
}

export default Vue.extend({
  name: 'MapTruckCardNew',

  mixins: [currentDomain],

  components: { RibbonBanner, TruckInfoNew, SlotGridNew, SelectTruckSize },

  props: {
    truckId: {
      type: String,
      required: true,
    },
    truckLocation: {
      type: String,
      default: '',
    },
    truckCounter: {
      type: String,
      default: '',
    },
    trucks: {
      type: Object as PropType<ITruckGroupBySize>,
      default: null,
    },
    truckList: {
      type: Array as PropType<Truck[]>,
      default: null,
    },
    resetCalendarPage: {
      type: Number,
      default: 0,
    },
    truckAvailable: Boolean,
    hideCloseButton: Boolean,
    defaultToDetailsTab: Boolean,
    hideCycling: Boolean,
    truckQuery: Boolean,
    triggerClick: Object,
  },

  data() {
    return {
      dayjs: dayjs,
      truck: null as Truck | null,
      tab: 0,
      calendar: {
        start: '',
        end: '',
      },
      calendar_auto_select: false,
      peaktime_selected: false,
      peaktime2_selected: false,
      offtime_selected: false,
      loader: false,

      rent_btn: {
        price: 0,
        loading: true,
      },
      awsPath: globalAws,
      selectedTruckSize: 'xxl',
    };
  },

  computed: {
    CART: () => CART,
    MAP: () => MAP,
    UTILS: () => UTILS,
    TRUCKS: () => TRUCKS,
    MISC_DATA: () => MISC_DATA,

    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },

    timePicked(): boolean {
      return CART.date_time_picked;
    },

    startTime(): string {
      if (this.timePicked) {
        // Timepicker
        return this.CART.last_time_search.start as string;
      }

      if (this.calendar.start) {
        return this.calendar.start;
      }

      return '';
    },

    endTime(): string {
      if (this.timePicked) {
        return this.CART.last_time_search.end as string;
      }

      if (this.calendar.end) {
        return this.calendar.end;
      }

      return '';
    },

    truckIsAvailable(): boolean {
      if (!this.truck || this.truckAvailable === undefined) {
        return false;
      }
      // truckAvailable comes from truck carousel and passed down availability since we don't have it in the truck response
      return this.truckAvailable;
    },

    isInTruckCarousel(): boolean {
      return !!this.$route.query.dialog && this.$route.query.dialog.includes('carousel');
    },

    truckLocationId(): string | null {
      if (this.truckLocation) {
        return this.truckLocation;
      }
      if (this.truck && this.truck.location instanceof Site) {
        return this.truck.location.id;
      }

      return null;
    },

    imgList(): string[] | null {
      const imgs = this.truck?.pictures;
      const pattern = {
        front_driver: 0,
        co_driver_open: 1,
        front: 2,
        co_driver: 3,
        back: 4,
        driver: 5,
        drivers_cab: 6,
        driver_open: 7,
        back_open: 8,
      } as { [index: string]: any };

      if (!imgs) {
        return null;
      }

      const imgsKeys = Object.keys(imgs);
      imgsKeys.sort((a: any, b: any) => {
        // Sort the keys following the pattern if duplicate they'll follow each other if not in the pattern they will be at the end
        return pattern[a] - pattern[b];
      });
      const pictures = [] as any;
      for (const i in imgsKeys) {
        if (imgs[imgsKeys[i]]) {
          pictures.push(imgs[imgsKeys[i]]);
        }
      }
      return pictures;
    },

    placeholderImage(): string {
      if (process.server) {
        return '';
      }
      return createPlaceholderImage({
        width: 600,
        text: this.$t('truck.pictures.none') as string,
      });
    },

    truckName(): string | undefined {
      if (!this.truck) {
        return;
      }
      const model = this.truck.model as Model;
      return `${model.manufacturer} ${model.model}`;
    },

    truckNumber(): string {
      const matches = this.truck?.name.match(/#\w+/g);
      if (matches && matches.length > 0 && matches[0].includes('#')) {
        return matches[0];
      }
      return '';
    },

    promoOneSlotIsActive(): boolean {
      if (!this.MISC_DATA.features) {
        return false;
      }
      return this.MISC_DATA.features['6_hour_blocks'] === true;
    },

    truckMinPrice6(): number {
      if (!(this.truck instanceof Truck)) {
        return 0;
      }

      if (this.truck['min-price'].price_6 > 0) {
        return this.truck['min-price'].price_6;
      }

      //fallback old logic

      const prices = this.MISC_DATA.prices;
      const size = (this.truck?.model as Model).size_group.toLowerCase();

      switch (size) {
        case 'l':
          return prices.truck1_6off;
        case 'xl':
          return prices.truck2_6off;
        case 'xxl':
          return prices.truck3_6off;
      }

      return 0;
    },

    truckMinPrice24(): number {
      if (!(this.truck instanceof Truck)) {
        return 0;
      }

      if (this.truck['min-price'].price_24 > 0) {
        return this.truck['min-price'].price_24;
      }

      //fallback old logic

      if (!(this.truck.model instanceof Model)) {
        return 0;
      }

      const size = {
        L: 'truck1',
        XL: 'truck2',
        XXL: 'truck3',
      } as StringIndex;

      const price_key = size[this.truck.model.size_group];

      return MISC_DATA.prices[price_key];
    },

    bookingBtnText(): string {
      return this.$t('actions.book_now_promo', {
        price: this.$n(this.rent_btn.price, 'currencyShort'),
      }) as string;
    },

    mainImage(): string {
      if (!(this.truck instanceof Truck)) {
        return '';
      }

      const size = (this.truck?.model as Model).size_group.toLowerCase();

      switch (size) {
        case 'l':
          return this.awsPath + '/img/truck_mockups/new-images/new-l.png';
        case 'xl':
          return this.awsPath + '/img/truck_mockups/new-images/new-xl.png';
        case 'xxl':
          return this.awsPath + '/img/truck_mockups/new-images/new-xxl.png';
      }

      return '';
    },
  },

  watch: {
    async truckId() {
      if (this.hideCycling) {
        this.clearCalendarSlots();
      }
      this.offtime_selected = false;
      this.peaktime_selected = false;
      this.peaktime2_selected = false;
      await this.getTruck();
    },

    truckQuery() {
      if (!this.truckQuery) {
        // edge case fix (opening a truck with QRcode then closing it and opening another one would keep calendar_auto_select to true without watching the prop)
        this.calendar_auto_select = false;
      }
    },

    timePicked() {
      // Important to reset calendar when times are chosen in the timepicker.
      this.clearCalendarSlots();
    },
  },

  async mounted() {
    await this.getTruck();
    if (this.defaultToDetailsTab) {
      this.tab = 1;
    }
  },

  async created() {
    if (this.truckQuery) {
      this.CART.resetDateTimePicked(); // Clear in case dateTimePicked - Messes up with the calendar.
      await this.getTruck();
      this.calendar_auto_select = true;
    }
  },

  methods: {
    async getTruck(): Promise<void> {
      if (this.TRUCKS.current_truck && this.TRUCKS.current_truck.id === this.truckId) {
        this.truck = this.TRUCKS.current_truck.copy();
        return;
      }
      this.loader = true;

      const res = await this.TRUCKS.GET_TRUCK_DATA({ truck_id: this.truckId });

      if (res instanceof ErrorResponse) {
        // error handling?
        this.$emit('close');
        this.loader = false;
        return;
      }

      this.truck = res;
      this.loader = false;
    },

    async handleSlotsSelected(payload: { start: string; end: string }): Promise<void> {
      this.rent_btn.loading = true;

      if (this.truck === null) {
        return;
      }

      // enforce timestamps to hace Europe/Vienna TZ (thats what we expect for now)
      const time_start = new Date(dateToAustrianTimezone(payload.start).toISOString());
      const time_end = new Date(dateToAustrianTimezone(payload.end).toISOString());

      const resp = await TRUCKS.GET_RENT_RPICE({ truck_id: this.truck.id, time_start, time_end });
      if (!(resp instanceof ErrorResponse)) {
        this.rent_btn.price = resp.amount;
        this.rent_btn.loading = false;
      }

      this.calendar = payload;
      const tracking_payload = this.createSlotTrackingPayload();
      if (tracking_payload) {
        this.$tracking.slot_clicked(tracking_payload);
      }
    },

    triggerTruckClickEvent(val: any) {
      if (!this.truck || !this.truck.id || !this.truck.location) {
        return;
      }

      const [page, page_num] = this.truckCounter ? this.truckCounter.split('/') : [1, 1];

      const payload = {
        truck_id: this.truck.id,
        site_id: this.truckLocationId,
        lat: (this.truck.location as Site)?.lat ?? 0,
        lng: (this.truck.location as Site)?.lng ?? 0,
        page: +page,
        num_pages: +page_num,
        blocked_slots: val.blocked_slots,
        available_slots: val.available_slots,
      } as ITruckClickPayLoad;

      this.$tracking.truck_click(payload);
    },

    emitCarouselData(val: any) {
      this.$emit('carouselData', val);
    },

    formatDate(date: string): string {
      if (this.isHuDomain) {
        return dayjs(date).format('dd, MMMM DD, HH:mm');
      }

      return dayjs(date).format('dd, DD. MMMM, HH:mm');
    },

    createSlotTrackingPayload(): ISlotCLickedOrConfirmedPayload | null {
      if (!this.truck) {
        return null;
      }
      return {
        truck_id: this.truckId,
        site_id: this.truckLocationId as string,
        price: this.rent_btn.price,
        time_start: this.calendar.start,
        time_end: this.calendar.end,
        truck_size: (this.truck.model as Model).size_group,
      };
    },

    takeCalendarSlots(): void {
      // Store the last date/times searched - If a booking exists it will update it with the new dates.
      this.CART.STORE_DATE_TIME_PICKED({
        start: this.calendar.start,
        end: this.calendar.end,
      } as IDateTimeSearch);

      // Trigger slot_confirm tracking
      const payload = this.createSlotTrackingPayload();
      if (payload) {
        this.$tracking.slots_confirm(payload);
      }

      this.toNextStep();
    },

    clearCalendarSlots(): void {
      this.calendar.start = '';
      this.calendar.end = '';
      this.offtime_selected = false;
      this.peaktime_selected = false;
      this.peaktime2_selected = false;
    },

    toNextStep(): void {
      this.loader = true;
      // Basically move on to upsells 1 sending back the truck id - Date times are stored in CART at this point
      // Have to emit to parent here because of dialog query.
      this.$emit('to-upsells', this.truck?.id);
    },

    updateTruckSize(val: string) {
      this.$emit('updateTruckSize', val);
    },
  },
});
