
import Vue from 'vue';

import MISC_DATA from '@/store/modules/MiscDataModule';
import protection_packages from '@/_helpers/protection_packages';

export default Vue.extend({
	name: 'ProtectionPackageCard',

	props: {
		package: {
			type: String,
			required: true,
		},
	},

	computed: {
		MISC_DATA: () => MISC_DATA,

		translation_key(): string {
			return 'upsells.packages.features.' + this.package.toLowerCase();
		},

		card_title(): string {
			return this.$t(this.translation_key + '.name') as string;
		},

		card_color(): string {
			return protection_packages[this.package].color;
		},

		// get 24 hour default price from products list (basedata)
		price(): number {
			const key = protection_packages[this.package].product;
			const product = this.MISC_DATA.basedata.prices.filter((product) => product.type == key);

			return product.length > 0 ? product[0].price : 0;
		},

		// get max_coverage from basedata
		max_coverage(): number {
			const product = this.MISC_DATA.basedata.max_deductibles.filter(
				(product) => product.type == this.package
			);
			return product.length > 0 ? product[0].value : 0;
		},

		bulletpoints(): string[] {
			return protection_packages[this.package].features;
		},
	},
});
