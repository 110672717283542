export default {
  BASIC: {
    color: 'cyan123',
    hex_color: '#5FBEC3',
    product: 'PROT1',
    features: ['upsells.packages.features.basic.bp0', 'upsells.packages.features.basic.bp1'],
  },
  STANDARD: {
    color: 'orange123',
    hex_color: '#F9653B',
    product: 'PROT2',
    features: [
      'upsells.packages.features.standard.bp0',
      'upsells.packages.features.standard.bp1',
      'upsells.packages.features.standard.bp2',
    ],
  },
  ZERO: {
    color: 'pink123',
    hex_color: '#F03899',
    product: 'PROT3',
    features: [
      'upsells.packages.features.zero.bp0',
      'upsells.packages.features.zero.bp1',
      'upsells.packages.features.zero.bp2',
      'upsells.packages.features.zero.bp3',
      'upsells.packages.features.zero.bp4',
      'upsells.packages.features.zero.bp5',
    ],
  },
  SMART_EASY: {
    color: 'pink123',
    hex_color: '#F03899',
    product: 'PROT4',
    features: [
      'upsells.packages.features.smart_easy.bp0',
      'upsells.packages.features.smart_easy.bp1',
      'upsells.packages.features.smart_easy.bp2',
    ],
  },
} as {
  [key: string]: {
    color: string;
    hex_color: string;
    product: string;
    features: string[];
  };
};
