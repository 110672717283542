
import Vue from 'vue';
import dayjs from 'dayjs';
import { ErrorResponse } from '~/types/api_helper';

import TRUCKS from '@/store/modules/TruckModule';
import CART from '@/store/modules/CartModule';
import MISC_DATA from '@/store/modules/MiscDataModule';

import CalendarSlotNew from '@/components/general/calendar/SlotNew.vue'
import { SlotType } from '~/store/modules/CartModule';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import globalAws from '@/mixins/globalAws';
import currentDomain from '@/mixins/currentDomain';

dayjs.extend(weekOfYear);
dayjs.extend(isoWeeksInYear);

export default Vue.extend({
	name: 'SlotGridNew',

	mixins: [currentDomain],

	components: { CalendarSlotNew },

	props: {
		truckId: {
			type: String,
			required: true
		},

		truckLocation: {
			type: String,
			required: false
		},

		autoSelect: {
			type: Boolean,
			default: false
		},

		resetCalendarPage: {
			type: Number,
			default: 0
		},

		triggerClick: Object,
	},

	data() {
		return {
			dayjs: dayjs,
			start_date: dayjs().format('YYYY-MM-DD').concat('T00:00'),
			end_date: dayjs().add(8, 'weeks').format('YYYY-MM-DD').concat('T00:00'),
			unavailable_slots: [] as number[],
			selected_slots: [] as number[],
			current_page: 0,
			weeks_requested: 8,

			loading: false,
			currentDate: dayjs(),
			awsPath: globalAws,
		};
	},

	computed: {
		TRUCKS: () => TRUCKS,
		CART: () => CART,
		MISC_DATA: () => MISC_DATA,

		isMobile() : boolean {
			return this.$vuetify.breakpoint.smAndDown
		},

		time_row() {
			if (this.isMobile) {
				return ['', '00:00<br>-<br>05:59', '06:00<br>-<br>11:59', '12:00<br>-<br>17:59', '18:00<br>-<br>23:59'];
			}

			return [' ', '00:00 - 05:59', '06:00 - 11:59', '12:00 - 17:59', '18:00 - 23:59'];
		},

		promoOneSlotIsActive(): boolean {
			if (!this.MISC_DATA.features) {
				return false
			}
			return this.MISC_DATA.features['6_hour_blocks'] === true;
		},

		peakTimeSlots(): number[] {
			if (!this.MISC_DATA.features && !this.MISC_DATA.features.peaktime_slots) {
				return [];
			}
			return this.MISC_DATA.features.peaktime_slots;
		},

		peakTime2Slots(): number[] {
			if (!this.MISC_DATA.features && !this.MISC_DATA.features.peaktime2_slots) {
				return [];
			}
			return this.MISC_DATA.features.peaktime2_slots;
		},

		offTimeSlots(): number[] {
			if (!this.MISC_DATA.features && !this.MISC_DATA.features.offtime_slots) {
				return [];
			}
			return this.MISC_DATA.features.offtime_slots;
		},

		daysAmount(): number {
			return 7
		},

		selectionStart(): string | undefined {
			if (this.selected_slots.length < 1) {
				return;
			}
			const start_index = Math.min(...this.selected_slots);
			return this.translateToDateTime(start_index);
		},

		selectionEnd(): string | undefined {
			if (!this.selectionStart) {
				return
			}
			if (this.selected_slots.length === 1) {
				return dayjs(this.selectionStart).add(359, 'minute').format('YYYY-MM-DDTHH:mm');
			}
			return this.translateToDateTime(Math.max(...this.selected_slots), true);
		},

		currentWeekDates(): string[] {
			let starting_date = dayjs(JSON.parse(JSON.stringify(this.start_date))).format();
			const end_date = dayjs(JSON.parse(JSON.stringify(this.end_date))).format();
			const res = []
			// Create a full array of dates from start to end
			while (starting_date <= end_date) {
				res.push(starting_date)
				starting_date = dayjs(starting_date).add(1, 'day').format();
			}
			// Return the current chunked week using the current_page
			const chunk_start = this.current_page > 0 ? this.daysAmount * this.current_page : 0;
			return res.slice(chunk_start, chunk_start + 7)
		},

		startOfWeek(): any {
			return this.currentDate.startOf('week').format('YYYY-MM-DD');
		},

		endOfWeek(): any {
			return this.currentDate.endOf('week').format('YYYY-MM-DD');
		}
	},

	watch: {
		async truckId() {
			this.reset();
			await this.fetchSlots();
		},
		//Handle the emits for start and end // clear
		selected_slots(newVal: any, oldVal: any) {
			if (newVal.length === 1) {
				this.CART.IS_SINGLE_DAY(true);
			} else {
				this.CART.IS_SINGLE_DAY(false);
			}

			if (this.selected_slots.length > 0) {
				// here handle the slot
				this.handleSlotType();
				this.$emit('slot-times', { start: this.selectionStart, end: this.selectionEnd });
			} else {
				// clear stored slot
				this.CART.CLEAR_SINGLE_SLOT();
				this.$emit('clear-selection');
			}
		},

		triggerClick: {
			handler(newVal, oldVal) {
				this.$nextTick(() => {
					if (newVal.triggerClick && (newVal.triggerId === this.truckId)) {
						this.emitDataToCarousel();
					}
				});
			},
			deep: true,
		},

		resetCalendarPage(newVal: Number, oldVal: Number) {
			if (newVal !== oldVal) {
				this.current_page = 0;
			}
		}
	},

	async mounted() {
		await this.fetchSlots();
	},

	methods: {
		async fetchSlots(): Promise<void> {
			this.loading = true;

			this.$logger.console({ message: `fetch calendar slots - location: ${this.truckLocation}` });

			const res = await this.TRUCKS.FETCH_TRUCK_SLOTS({
				truck_id: this.truckId,
				truck_location: this.truckLocation,
				start: this.start_date,
				end: this.end_date
			});
			this.loading = false;

			if (res instanceof ErrorResponse) {
				return
			}
			let i = 0
			// Check for past slots in the first day and add them to unavailable slots
			while (i <= 3) {
				if (dayjs(this.translateToDateTime(i, true)) < dayjs()) {
					res.push(i)
				}
				i++
			}
			this.unavailable_slots = res;
			if (this.weeks_requested < 16) {
				this.storeFirstWeekSlotStatus();
			}
			if (this.autoSelect) {
				this.selectFirst4Slots();
			}
		},
		// Use to emit the first week slots status for the GTM event
		storeFirstWeekSlotStatus(): void {
			// only take slots on the first week (4 * 7 days = 28 slots in a 0 based array)
			const first_week_unavailable_slots = this.unavailable_slots.filter(s => s < 27);
			const unavailable_slots_amount = first_week_unavailable_slots.length;
			// difference to 4 * 7 days
			const available_slots_amount = 28 - unavailable_slots_amount; 
			// Save in truck module {id: truck, blocked_slots: number, available_slots: number}
			this.TRUCKS.storeCurrentWeekSlotsStatus({
				id: this.truckId,
				blocked_slots: unavailable_slots_amount,
				available_slots: available_slots_amount
			});

			const payload = {
				id: this.truckId,
				blocked_slots: unavailable_slots_amount,
				available_slots: available_slots_amount
			};

			this.$emit('slotsPayload', payload);
		},

		warpIndex(index: number): number {
			return index + (this.daysAmount * 4) * this.current_page
		},

		slotClicked(index: number): void {
			const selected = JSON.parse(JSON.stringify(this.selected_slots));
			// More than 1 index in selected_slots -> empty and set this one.
			if (selected.length > 1) {
				this.selected_slots = [index]
				return;
			}
			// Only 1 slot in selected_slots.
			if (selected.length === 1) {
				if (selected.includes(index)) { // It's the same -> empty (unselect).
					this.selected_slots = [];
				} else if (selected[0] > index) { // it's in the past -> replace
					this.selected_slots = [index];
				} else { // Process range.
					this.selectRange(selected[0], index);
				}
				return;
			}
			// Nothing selected
			this.selected_slots.push(index)
		},

		selectRange(start: number, end: number): void {
			// Check all slots in between start and end -> if one is unavailable, stop the selection here.
			let current_index = start + 1;
			let selection = [start];

			while (current_index <= end) {
				if (this.unavailable_slots.includes(current_index)) {
					if (current_index === start + 1) { // Edge case where one slot was picked right before an unavailable one
						selection = [end]
					}
					break;
				}
				selection.push(current_index)
				current_index++
			}
			this.selected_slots = selection;
		},

		selectFirst4Slots(): void {
			let i = 0;
			while (i < 28) {
				if (this.unavailable_slots.includes(i)) {
					if (this.selected_slots.length > 0) {
						break
					}
					i++
					continue
				}
				if (this.selected_slots.length >= 4) {
					break
				}
				this.selected_slots.push(i)
				i++
			}
		},

		// Method to calculate date and time of an index
		translateToDateTime(index: number, end?: boolean): string {
			const time_slots = end ? ['05:59', '11:59', '17:59', '23:59'] : ['00:00', '06:00', '12:00', '18:00']
			// By using the modulus of the index by 4, we get the index of the col in a row.
			const col_pos = index % 4
			// We can therefore use it in time_slots to get the corresponding time.
			const time = time_slots[col_pos];
			// Substracting the col index from the index will give us the multiple of 4 from this row (1st slot).
			// Divided by 4 to get number of days to add from the start date.
			const days_from_start = (index - col_pos) / 4
			const start_date = dayjs(this.start_date).format('YYYY-MM-DD');

			return dayjs(start_date).add(days_from_start, 'days').format('YYYY-MM-DD').concat(`T${time}`);
		},

		handleSlotType(): void {
			if (this.selected_slots.length > 1 || !this.promoOneSlotIsActive) {
				this.CART.CLEAR_SINGLE_SLOT();
				return
			}

			let slot = SlotType.Default;

			if (this.isPromoSlotCheck(this.peakTimeSlots)) {
				slot = SlotType.Peaktime;
			} else if (this.isPromoSlotCheck(this.peakTime2Slots)) {
				slot = SlotType.Peaktime2;
			} else if (this.isPromoSlotCheck(this.offTimeSlots)) {
				slot = SlotType.Offtime;
			} else {
				slot = SlotType.Promo6;
			}

			this.CART.STORE_SINGLE_SLOT_TYPE(slot);
		},

		isPromoSlotCheck(promo_slots: number[]): any {
			let day_index = dayjs(this.selectionStart).day() - 1;
			const hour = dayjs(this.selectionStart).hour()
			let hour_index = 0;
			switch (hour) {
				case 0:
					hour_index = 0;
					break;
				case 6:
					hour_index = 1;
					break;
				case 12:
					hour_index = 2;
					break;
				case 18:
					hour_index = 3;
			}
			if (day_index < 0) { // case when sunday cause it's 0 by default
				day_index = 6
			}
			const index = (day_index * 4) + hour_index;
			return promo_slots.some((slot: number) => index === slot - 1); // -1 cause monday is 1 in the promo lists
		},

		async nextPage(): Promise<void> {
			if (this.current_page >= this.weeks_requested - 1) { // Buffer
				// Request 8 more weeks
				this.end_date = dayjs(JSON.parse(JSON.stringify(this.end_date))).add(8, 'weeks').format('YYYY-MM-DD').concat('T00:00')
				this.weeks_requested += 8
				await this.fetchSlots();
			}
			this.current_page++
			this.currentDate = this.currentDate.add(1, 'week');
		},

		prevPage() {
			this.current_page--;
			this.currentDate = this.currentDate.subtract(1, 'week');
		},

		reset() {
			this.selected_slots = []
			this.weeks_requested = 8
			this.end_date = dayjs().add(8, 'weeks').format('YYYY-MM-DD').concat('T00:00');
		},

		emitDataToCarousel() {
			// only take slots on the first week (4 * 7 days = 28 slots in a 0 based array)
			const first_week_unavailable_slots = this.unavailable_slots.filter(s => s < 27);
			const unavailable_slots_amount = first_week_unavailable_slots.length;
			// difference to 4 * 7 days
			const available_slots_amount = 28 - unavailable_slots_amount; 
			// Save in truck module {id: truck, blocked_slots: number, available_slots: number}
			this.TRUCKS.storeCurrentWeekSlotsStatus({
				id: this.truckId,
				blocked_slots: unavailable_slots_amount,
				available_slots: available_slots_amount
			});

			const payload = {
				id: this.truckId,
				blocked_slots: unavailable_slots_amount,
				available_slots: available_slots_amount
			};

			this.$emit('carouselData', payload);
		},

	}
});
