
import Vue from 'vue';
import type { PropType } from 'vue';

import UTILS from '@/store/modules/UtilityModule';
import TRUCKS from '@/store/modules/TruckModule';
import MISC_DATA from '@/store/modules/MiscDataModule';

import { Truck, ITruckGroupBySize } from '@/types/truck';

import MapTruckCarousel from '@/components/map/MapTruckCarousel.vue';

import globalAws from '@/mixins/globalAws';

export default Vue.extend({
	name: 'MapChooseSize',

	components: { MapTruckCarousel },

	props: {
		trucks: {
			type: Object as PropType<ITruckGroupBySize>,
			default: null
		},
		siteId: {
			type: String,
			default: ''
		},
	},

	data() {
		return {
			picked_pool: [] as Truck[],
			componentUpdate: 0,
			awsPath: globalAws,
			showFailedMessage: false,
			errorMessage: ''
		};
	},

	computed: {
		UTILS: () => UTILS,
		TRUCKS: () => TRUCKS,
		MISC_DATA: () => MISC_DATA,

		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},

		dialogTruckCarousel(): boolean {
			return this.$route.query.dialog === 'dialog-tr-carousel';
		},

		sizeCards(): any {
			const size_cards = [
				{
					label: 'L',
					color: 'cyan123',
					truck_amount: this.trucks.l ? this.trucks.l.length : 0,
					truck_price: this.MISC_DATA?.truck_min_price_per_location?.L?.price_24,
					truck_price_6hours: this.MISC_DATA?.truck_min_price_per_location?.L?.price_6
				},
				{
					label: 'XL',
					color: 'orange123',
					truck_amount: this.trucks.xl ? this.trucks.xl.length : 0,
					truck_price: this.MISC_DATA?.truck_min_price_per_location?.XL?.price_24,
					truck_price_6hours: this.MISC_DATA?.truck_min_price_per_location?.XL?.price_6,
				},
				{
					label: 'XXL',
					color: 'pink123',
					truck_amount: this.trucks.xxl ? this.trucks.xxl.length : 0,
					truck_price: this.MISC_DATA?.truck_min_price_per_location?.XXL?.price_24,
					truck_price_6hours: this.MISC_DATA?.truck_min_price_per_location?.XXL?.price_6
				}
			];
			return size_cards.filter((c: any) => c.truck_amount > 0);
		},

		showPromoForOneSlot(): boolean {
			if (!this.MISC_DATA.features) {
				return false;
			}
			return this.MISC_DATA.features['6_hour_blocks'] === true;
		}
	},

	methods: {
		pickedSize(size: string): void {
			this.componentUpdate += 1;
			this.picked_pool = this.trucks[size];
			this.UTILS.openDialog('dialog-tr-carousel');
		},

		async getMinPrices() {
			const response = await this.MISC_DATA.GET_TRUCK_MIN_PRICES_PER_LOCATION({ domain: this.$getDomain(), site: this.siteId });

			if (response.status !== 200) {
				this.errorMessage = response.data.message;
				this.showFailedMessage = true;
			} else {
				this.showFailedMessage = false;
			}
		}
	},

	watch: {
		siteId: {
			async handler(newVal: string, oldVal: string,) {
				if (newVal !== oldVal) {
					const response = await this.MISC_DATA.GET_TRUCK_MIN_PRICES_PER_LOCATION({ domain: this.$getDomain(), site: newVal });
					if (response.status !== 200) {
						this.errorMessage = response.data.message;
						this.showFailedMessage = true;
					} else {
						this.showFailedMessage = false;
					}
				}
			},
			immediate: true,
		},
	}
});
