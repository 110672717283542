
  export default {
	name: 'ABTestWrapper',
	props: {
	  experimentId: {
		type: String,
		required: true
	  },
	  variations: {
		type: Array,
		required: true
	  },
	  defaultVariation: {
		type: String,
		required: true
	  }
	},
	data() {
	  return {
		selectedVariation: null
	  }
	},
	mounted() {
	  console.info('Convert App, experimetnt ID: ', this.experimentId, 'Variations: ', this.variations, 'Default Variation: ', this.defaultVariation);
	  this.initializeTest()
	},
	methods: {
	  getCurrentVariation(id) {
		// Check if window is undefined
		if (typeof window === 'undefined') {
			console.warn('Convert App: Window is undefined');
			return this.variations.find(v => v.variationId === this.defaultVariation);
		}

		var activeExperiments = window.convert?.currentData?.experiments;

		// Check if active experiments are not defined
		if (!activeExperiments) {
			console.warn('Convert App: No Active Experiments');
			return this.variations.find(v => v.variationId === this.defaultVariation);
		} 

		// Check if there are 'Active' experiments with this ID
    	var isExperimentActive = activeExperiments.hasOwnProperty(id);
		var variationId;

		if (isExperimentActive) {
		  variationId = activeExperiments[id].variation_id;
		} else {
		  variationId = this.defaultVariation
		}

		return this.variations.find(v => v.variationId === variationId) || this.variations.find(v => v.variationId === this.defaultVariation);
	  },
	  initializeTest() {
		if (typeof window.convert === 'undefined') {
		  console.warn('Convert App: Convert script not loaded or not initialized');
		  this.selectedVariation = this.variations.find(v => v.variationId === this.defaultVariation)
		  return
		}
  
		this.selectedVariation = this.getCurrentVariation(this.experimentId)
	  }
	}
  }
  